import React, { useContext } from 'react'
import moment from 'moment'
import Img from "gatsby-image"
import { Link } from 'gatsby'

import { CartContext } from '../../context/CartContext';

import { formatPrice } from '../../helpers/currency';
import { cartSubTotal } from '../../helpers/cart';

import MakeoverDayIllustration from '../../images/svgs/makeover-day.svg'
import SpecialGiftIllutration from '../../images/svgs/special-message.svg'

export default function Cart() {

	const { cart, addToCart, removeFromCart } = useContext( CartContext );

	const incrementQuantity = ( increment, product, variationId ) => {
		addToCart( product, increment, variationId, false)
	}

	const quantityInStock = ( product, id ) => {
		return product.variationsAndQuantities.find( node => node.id === id ).quantity
	}

	return (
		<div className="cart">

			<div className="cart__header">
				<h1 className="cart__heading">Your Shopping Bag</h1>
			</div>

			<div className="cart__products-container">
				{ cart.map( ( product, i ) => (
					<React.Fragment key={ i }>
						{ product.__typename === "MakeoverDays" ? (
							<div className="cart__product">
								<Link className="cart__product-link" to="/makeover-days">
									<MakeoverDayIllustration className="cart__product-image cart__product-image--illustration" />
								</Link>
								<div className="cart__product-info">
		
									<div className="cart__product-info-item">
										<Link className="cart__product-link" to="/makeover-days">
											<div className="cart__product-name">Makeover Day</div>
										</Link>
										<span className="cart__product-details">{ moment( product.Date ).format("Do MMM YYYY") }</span>
									</div>

									<div className="cart__product-info-item">
										<div className="cart__inputs-container">
											<div className="cart__quantity-label u-hide-phablet">Quantity</div>
											<div className="cart__quantity-container">
												<button className={`cart__quantity-trigger cart__quantity-trigger--decrease ${ product.quantity === 1 || !product.spaceAvailable ? 'disabled' : '' }`} onClick={ () => incrementQuantity( -1, product, product.id ) }>
													_
												</button>
												<div className="cart__quantity-input">
													{ product.quantity }
												</div>
												<button className={`cart__quantity-trigger ${ product.quantity === product.spaceAvailable || !product.spaceAvailable ? 'disabled' : '' }`} onClick={ () => incrementQuantity( 1, product, product.id ) }>
													+
												</button>
											</div>
										</div>
									</div>
									<div className="cart__product-info-item cart__product-info-item--price">{ formatPrice( product.price ) }</div>

									<div className="cart__product-info-item cart__product-info-item--remove">
										<button className="cart__product-remove" onClick={ () => removeFromCart( product, product.id ) }>
											<span className="cart__product-remove-label u-hide-phablet">delete item</span> x
										</button>
									</div>
								</div>
							</div>
						) : (
							product.giftCard ? (
								<div className="cart__product">
								<Link className="cart__product-link" to={`/products/${ product.slug }`}>
									<SpecialGiftIllutration className="cart__product-image cart__product-image--illustration" />
								</Link>
								<div className="cart__product-info">
									<div className="cart__product-info-item">
										<Link className="cart__product-link" to={`/products/${ product.slug }`}>
											<div className="cart__product-name">Gift Voucher </div>
										</Link>
									</div>
									<div className="cart__product-info-item">
										<div className="cart__inputs-container">
											<div className="cart__quantity-label u-hide-phablet">Quantity</div>
											<div className="cart__quantity-container">
												<button className={`cart__quantity-trigger cart__quantity-trigger--decrease ${ product.quantity === 1 ? 'disabled' : '' }`} onClick={ () => incrementQuantity( -1, product, product.id ) }>
													_
												</button>
												<div className="cart__quantity-input">
													{ product.quantity }
												</div>
												<button className="cart__quantity-trigger" onClick={ () => incrementQuantity( 1, product, product.id ) }>
													+
												</button>
											</div>
										</div>
									</div>
									<div className="cart__product-info-item cart__product-info-item--price">{ formatPrice( product.value ) }</div>

									<div className="cart__product-info-item cart__product-info-item--remove">
										<button className="cart__product-remove" onClick={ () => removeFromCart( product, product.value ) }>
											<span className="cart__product-remove-label u-hide-phablet">delete item</span> x
										</button>
									</div>
								</div>
							</div>
							) : (
								<>
									{ product.quantity.map( variation => (
										<div className="cart__product" key={ `${ product.id } ${ variation.variationId }` }>
											<Link className="cart__product-link" to={`/products/${ product.slug }`}> 
												<Img className="cart__product-image" fluid={ product.images[0].imageFile.childImageSharp.fluid } />
											</Link>
											<div className="cart__product-info">

												<div className="cart__product-info-item">
													<Link className="cart__product-link" to={`/products/${ product.slug }`}>
														<div className="cart__product-name">{ product.name }</div>
													</Link>
													{ product.colour_option && <span className="cart__product-details">{ product.colour_option.name }</span> }
													{ variation && variation.size && variation.size.size && <span className="cart__product-details">{ variation.size.size }</span> }
												</div>

												<div className="cart__product-info-item">
													<div className="cart__inputs-container">
														<div className="cart__quantity-label u-hide-phablet">Quantity</div>
														<div className="cart__quantity-container">
															<button className={`cart__quantity-trigger cart__quantity-trigger--decrease ${ variation.quantity === 1 || !quantityInStock( product, variation.variationId ) ? 'disabled' : '' }`} onClick={ () => incrementQuantity( -1, product, variation.variationId ) }>
																_
															</button>
															<div className="cart__quantity-input">
																{ variation.quantity }
															</div>
															<button className={`cart__quantity-trigger ${ variation.quantity === quantityInStock( product, variation.variationId ) || !quantityInStock( product, variation.variationId ) ? 'disabled' : '' }`} onClick={ () => incrementQuantity( 1, product, variation.variationId ) }>
																+
															</button>
														</div>
													</div>
												</div>
												<div className="cart__product-info-item cart__product-info-item--price-container">
													{ product.salePrice &&
														<span className="cart__product-price cart__product-price--sale">{ formatPrice( product.salePrice ) }</span>
													}
													<span className={`cart__product-price cart__product-price ${product.salePrice ? "disabled" : ''}`}>{ formatPrice( product.price ) }</span>
												</div>

												<div className="cart__product-info-item cart__product-info-item--remove">
													<button className="cart__product-remove" onClick={ () => removeFromCart( product, variation.variationId ) }>
														<span className="cart__product-remove-label u-hide-phablet">delete item</span> x
													</button>
												</div>
											</div>
										</div>
									) ) }
								</>
						))}
					</React.Fragment>
				) ) }
			</div>

			<div className="cart__subtotal-container">
				<span className="cart__subtotal-label">Subtotal</span>
				<span className="cart__subtotal-price">{ formatPrice( cartSubTotal( cart ) ) }</span>
			</div>

			<div className="cart__buttons-container">
				<Link to="/checkout" className="button button--primary button--large">
					Checkout Now
				</Link>
				<Link to="/clothing" className="button button--secondary button--large">
					Back to Shopping
				</Link>
			</div>
		</div>
	)
}
