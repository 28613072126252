import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'gatsby'

import { CartContext } from '../context/CartContext';

import SEO from '../components/SEO/Seo'
import Cart from '../components/Cart/Cart'

import { GTViewCart } from '../helpers/gtagEvents'

import { FaSpinner } from 'react-icons/fa'

export default ({ location }) => {

	const { cart } = useContext( CartContext );
	const [ hasMounted, setHasMounted ] = useState(false);

	useEffect(() => {
		setHasMounted( true )
		GTViewCart( cart )
	}, [])

	if ( !hasMounted ) {
		return (
			<div className="account-page__loading-container">
				<FaSpinner className="loader" />
				<h3 className="account-page__loading-text">Loading</h3>
			</div>
		)
	}

	return (
		<>
			<SEO title="Shopping Bag" description="View products in your shopping bag on dream on." pathname={ location.pathname } />

			<div className="wrapper">
				{ cart && cart.length > 0 ? (
					<Cart />
				) : (
					<div className="cart">
						<div className="cart__header cart__header--empty-bag">
							<h1 className="cart__heading">Your shopping bag is empty</h1>
							<span className="cart__header-description">Please feel free to have a browse and add items to your shopping bag.</span>
							<Link to="/clothing" className="cart__header-button button button--primary">
								Back to Shopping
							</Link>
						</div>
					</div>
				)}
			</div>
		</>
	)
}
